import React from "react";
import { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { openModal } from "modules/modals/actions";
import { bindActionCreators } from "redux";
import styled, { css } from "styled-components";

import Account from "Components/Navigation/Components/Account";
import Hamburger from "./Components/Hamburger";
import Sidebar from "../Sidebar/Sidebar";

function mapStateToProps(state) {
  let title = state.page.title;
  if (!title) {
    title = "Welcome to Secure Finance";
  }
  return {
    browser: state.browser,
    title: title,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      openModal,
    },
    dispatch
  );
}

class Navigation extends Component {
  render() {
    return (
      <header className={this.props.state}>
        <div className="header-wrapper">
          <div className="left-navigation">
            {this.props.state === "unactive" ? (
              <a className="logo" href="https://securefinance.com.au">
                <img
                  src={require("img/brand/secure_finance.png")}
                  alt="loanBase logo"
                />
              </a>
            ) : (
              <NavLink className="logo" exact to="/">
                <img
                  src={require("img/brand/secure_finance.png")}
                  alt="loanBase logo"
                />
              </NavLink>
            )}
          </div>
          {NavigationType(
            this.props.state,
            this.props.title,
            this.props.openModal,
            this.props
          )}
        </div>
      </header>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);

function NavigationType(value, title, openModal, props) {
  switch (value) {
    case "unactive":
      return (
        <NavigationUnactive title={title} openModal={openModal} {...props} />
      );
    case "sidebar-navigation":
      return (
        <NavigationSidebar title={title} openModal={openModal} {...props} />
      );
    default:
      return (
        <NavigationActive title={title} openModal={openModal} {...props} />
      );
  }
}

class NavigationActive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenu: false,
      greaterThanW840: this.props.browser.greaterThan.w840,
    };
  }

  showMobileMenu() {
    this.setState({ mobileMenu: true });
    console.log(this.state.mobileMenu);
  }

  hideMobileMenu() {
    this.setState({ mobileMenu: false });
  }

  toggleMobileMenu() {
    this.setState((state) => ({ ...state, mobileMenu: !state.mobileMenu }));
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.browser.greaterThan.w840 !== prevState.greaterThanW840) {
      return {
        mobileMenu: false,
        greaterThanW840: nextProps.browser.greaterThan.w840,
      };
    }
  }

  render() {
    return (
      <div className="right-navigation">
        <div className="page-title">
          <span>{this.props.title}</span>
        </div>
        <div className="navigation-elements">
          <div className="contact">
            <a className="phone-number" href="tel:1800 931 678">
              1800 931 678
            </a>
            <button
              onClick={() => this.props.openModal("checkEligibilityHelp")}
              className="chat-button"
            >
              Contact Us
            </button>
          </div>
          <Account />
        </div>
        <MenuContainer onClick={() => this.toggleMobileMenu()}>
          <MenuText>MENU</MenuText>
          <HamburgerMenu
            mobileMenu={this.state.mobileMenu}
            toggleMobileMenu={() => this.toggleMobileMenu()}
          />
        </MenuContainer>
        <MobileMenu active={this.state.mobileMenu}>
          <Sidebar
            closeMobileMenu={() => this.hideMobileMenu()}
            state={this.state.width >= 951 ? "" : "mobile"}
          />
        </MobileMenu>
      </div>
    );
  }
}

class NavigationUnactive extends Component {
  render() {
    return (
      <div className="right-navigation">
        <div className="page-title">
          <span>{this.props.title}</span>
        </div>
        <div className="contact">
          <a className="phone-number" href="tel:1800 931 678">
            1800 931 678
          </a>
          <button
            onClick={() => this.props.openModal("checkEligibilityHelp")}
            className="chat-button"
          >
            Contact Us
          </button>
        </div>
      </div>
    );
  }
}

class NavigationSidebar extends Component {
  render() {
    return (
      <div className="contact">
        <h1>Mortgage Comparison</h1>
        <h2>Compare online in 30 seconds.</h2>
        <a className="phone-number" href="tel:1800 931 678<">
          <span>Call us:</span> 1800 931 678
        </a>
        <a
          onClick={() => this.props.openModal("checkEligibilityHelp")}
          className="chat-button"
        >
          Contact Us
        </a>
        <hr />
        <p>
          Secure Finance Services Pty Ltd. Australian Credit
          Licence Number 465059. 204/11 Solent Circuit, Norwest NSW 2153. By registering
          you are agreeing with our{" "}
          <a
          target="_blank"
          href="/privacy-policy"
          >
            Privacy Policy
          </a>
          .
        </p>
      </div>
    );
  }
}
const MobileMenu = styled.div`
  pointer-events: none;
  position: absolute;
  z-index: 1010;
  background-color: rgba(33, 61, 88, 0.9);
  opacity: 0;
  position: fixed;
  top: 80px;
  left: 0;
  width: 100%;
  height: 100% !important;
  transition: 0.2s opacity;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;

  ${(props) =>
    props.active &&
    css`
      pointer-events: all;
      opacity: 1;
    `}

  @media(max-width: 680px) {
    top: 60px;
  }

  .sidebar {
    display: block;
    border-right: none;
    left: auto;
    right: -260px;
    width: 260px;
    transition: 0.2s right;
    height: 100%;

    @media (max-width: 680px) {
      top: 60px;
      width: 100%;
      padding: 0 0 60px 0;
    }

    ${(props) =>
      props.active &&
      css`
        right: 0;
      `}

    ul {
      li {
        .shortlist-pill,
        .check-eligibility-pill,
        .proposal-pill {
          top: 21px;
          left: auto;
          transform: none;
        }

        a {
          font-size: 16px;
          font-weight: 400;
          padding: 25px 0 25px 90px;
          background-position: 50px 50%;
          text-align: left;

          &:hover {
            font-weight: 600;
            font-size: 16px;
          }

          &.current {
            font-weight: 600;
            font-size: 16px;

            &:after {
              width: 6px;
            }
          }

          span {
            display: block;

            &.condensed {
              display: none;
            }
          }
        }
      }
    }
  }
`;

const MenuContainer = styled.div`
  float: right;
  padding: 15px 15px;
  cursor: pointer;
  display: none;

  @media (max-width: 950px) {
    display: inline-block;
  }

  @media (max-width: 680px) {
    padding: 9px 10px;
  }
`;

const MenuText = styled.div`
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 600;
  color: #223241;
`;

const HamburgerMenu = styled(Hamburger)`
  padding: 12px 10px 12px 10px;
  margin-left: 5px;
  display: inline-block;
  vertical-align: middle;

  /* @media (max-width: 480px) {
    padding: 12px 10px 12px 10px;
  } */
`;

import React from "react"; import { Component } from "react";
import { reduxForm, formValueSelector } from "redux-form";
import { connect } from 'react-redux';
import LinkButton from "Components/LinkButton";
import { emailService } from 'modules';

import { Tabs, TabLink, TabContent } from "react-tabs-redux";
import { TextArea, FieldGroup, FormContainer, SelectGroup, DoubleFieldGroup } from "Components/Forms";
import { phoneNumberProps } from 'Components/Forms/validation';
class CheckEligibilityHelp extends Component {

  onSubmitCallback = () => {
    
  }

  onSubmit = values => {
    //console.log('submitting');
    emailService.create({
      type: 'call-request',
      ...values
    });
  }

  render() {

    const { callTime, submitSucceeded } = this.props;
    if (this.props.currentModal === "checkEligibilityHelp") {
    //console.log('props', this.props)
    return (
      <div className="modal-content">
        <h1>Pick how you want to get help</h1>

        <div className="tabs"><Tabs>
          <div className="navigation">
            <TabLink to="tab1" className="tab-navigation call"><span>Call</span></TabLink>
            <TabLink to="tab2" className="tab-navigation chat"><span>Chat</span></TabLink>
            {/* <TabLink to="tab3" className="tab-navigation help"><span>Help Centre</span></TabLink> */}
          </div>

          <div className="tab-content">
            <TabContent className="call content" for="tab1">
              {!submitSucceeded &&
                <div className="left">
                  <p>One of our loan experts can call you back at a time convenient for you!</p>
                  <FormContainer name='contact' onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    <DoubleFieldGroup {...phoneNumberProps} id='phoneNumber' width='small' position='left' title='Your Phone Number' required type='number-type' />
                    <TextArea id='comment' width='small' position='left' title='Comment or Question' hideOptional placeholder='E.g Help me select a loan' />
                    <label style={{ marginBottom: '0'}}>When would you like us to call?</label>
                    <div style={{ display: 'inline-block', width: '58%'} }>
                      <SelectGroup empty id='callTime' width='small' position='left' required type='select' values={['As Soon As Possible', 'Next Business Day', 'Saturday', 'Other']} />
                    </div>
                    {(callTime === 'Next Business Day' || callTime === 'Saturday') &&
                    <div style={{ display: 'inline-block', width: '42%', paddingLeft: '10px'} }>
                      <SelectGroup empty id='callTime2' width='small' position='right' title='' required type='select' values={['Morning', 'Afternoon', 'Evening']} />
                    </div>
                    }
                    {callTime === 'Other' ?
                    <div style={{ marginTop: '-30px'}}>
                      <FieldGroup id='callTimeOther' width='small' placeholder='E.g. Next Tuesday at 11am' position='right' required type='text'/>
                    </div> 
                    :
                    // <div style={{ width:'100%', height:'50px'}}/>
                    ""
                    }
                    <button type='submit' className="contact-me call">Call Me</button>
                  </FormContainer>
                </div>
              }
              {submitSucceeded &&
                <div className="left" style={{height: '463px'}}>
                  <div className='center-content'>
                    <p><strong>Awesome!</strong></p>
                    <p>One of our skilled brokers will be in touch soon to help 😃</p>
                  </div>
                </div>
              }

              <div className="right">
                {(!this.props.browser.greaterThan.w600) ||
                  <img src={require('../../../img/modal/checkeligibilityhelp.png')} alt="checkeligibilityhelp"/>
                }
                <h3>Or call us on</h3>
                <h2>1800 931 678 </h2>
                <p>We’re available from <br />9am-6:30pm Monday to Friday</p>
                {(this.props.browser.greaterThan.w600) ||
                  <img src={require('../../../img/modal/checkeligibilityhelp.png')} alt="checkeligibilityhelp"/>
                }
              </div>

            </TabContent>
            <TabContent className="chat content" for="tab2">
              <img src={require('../../../img/modal/checkeligibilityhelp-chat.png')} alt="checkeligibilityhelp"/>
              <button className="contact-me chat" onClick={()=>window.Intercom('show')}><span>Chat with Us</span></button>
              <p>We’re available to chat online from<br />9am - 10pm Monday to Friday</p>
            </TabContent>
            <TabContent className="help content" for="tab3">
              <img src={require('../../../img/modal/checkeligibilityhelp-helpcentre.png')} alt="checkeligibilityhelp"/>
              <p>We’ve got an online help centre which contains a lot<br />of useful tips and information that might help.</p>
              <LinkButton to="/help" onClick={()=>this.props.closeModal()} className="contact-me help">Take me to the help centre!</LinkButton>
              <div className="small-text">Don’t worry, you can always resume the qualification check!</div>
            </TabContent>
          </div>
        </Tabs></div>

      </div>
    ) } else {
      return "";
    }
  }
}

CheckEligibilityHelp = reduxForm({
  form: "contact", // a unique identifier for this form
})(CheckEligibilityHelp);

const selector = formValueSelector('contact');
const stateToProps = state => ({
  initialValues: { 
    phoneNumber: state.user.phoneNumber,
    phoneNumberType: state.user.phoneNumberType,
    callTime: 'As Soon As Possible',
    callTime2: 'Morning'
  },
  callTime: selector(state, 'callTime'),
})

export default connect(stateToProps)(CheckEligibilityHelp);
import React from "react";
import styled, { css } from 'styled-components';
import ReactDOM from 'react-dom';
import { bindActionCreators } from 'redux';
import { reduxForm, Form, formValueSelector } from 'redux-form';
import { connect } from 'react-redux'
import { openModal } from "modules/modals/actions";
import SectionButton from "../../../component/SectionButton";
import { requestPatchUser } from 'modules/user/actions';
import { Container, Heading, EditableParagraph } from './Shared';
import { uploadUrl } from 'modules';
import { lighten } from "polished";
import { TextArea as TextAreaBase } from "Components/Forms";
import { ButtonSpinner } from "Components/Global/ButtonSpinner";
import { emailService } from "modules";
import sanitizeHtml from 'sanitize-html';


import _ from 'lodash';
const $ = window.$;


const FORM_NAME = "overview-next-loanbase";
const BODY_NAME = "overview_next_body"

class NextSteps extends React.Component {

  constructor(props) {
    super();
    this.inputRef = React.createRef()
    this.state = {
      questionActive: false,
      questionTransitioning: false,
      questionSubmitted: false,
    }
  }

  cancel = () => {
    this.props.reset();
    this.props.cancelEdit();
  }

  resetSection = () => {
    const body = this.props.globalSettings[BODY_NAME];
    const formValues = { body };
    this.patchProposal(formValues);
    this.props.initialize(formValues);
    this.props.reset();
  }

  save = () => {
    let values = {...this.props.formValues};
    delete values.enabled;
    if (!values.body) values.body = this.props.globalSettings[BODY_NAME];
    this.patchProposal(values);
    this.props.initialize(values);

    this.props.cancelEdit();
  }

  patchProposal = (data, callback) => {
    let toSave;
    
    toSave = {
      overview: {
        nextSteps: data
      }
    }
    
    this.props.requestPatchUser({ 
      userId: this.props.user.id,
      params: {
        mergeMeta: true,
      },
      data: { 
        meta: { 
          miniproposal: toSave,
        } 
      },
      callback, 
    });
  } 

  enableEdit = () => {
    setTimeout(() => {
      this.inputRef.current.focus();
    });

    this.props.toggleActive();
  }

  blurOnEnter() {
    document.activeElement.blur();
  }

  questionActive() {
    this.setState({ questionActive: true, questionTransitioning: true }, () => {
      setTimeout(() => this.setState({ questionTransitioning: false }), 1000);
    });
  }

  async submitQuestion() {
    const { question } = this.props;
    this.setState({ questionSubmitting: true, questionActive: false, questionTransitioning: true }, () => {
      setTimeout(() => this.setState({ questionTransitioning: false }), 1000);
    });
    console.log('Sending Question:', question);
    await this.sendQuestion(question);
    this.setState({ questionSubmitting: false, questionSubmitted: true})
  }

  sendQuestion = question => {
    return new Promise(async (resolve, reject) => {
      try {
        await emailService.create({
          type: "proposal-question",
          question
        });
        this.props.dispatch(this.props.change("question", ""));
        this.props.dispatch(this.props.untouch("question"));
        resolve();
      } catch (err) {
        reject();
      }
    });
  };

  render() {
    const { user, active, editMode, browser, globalSettings } = this.props;
    const { questionActive, questionTransitioning, questionSubmitting, questionSubmitted } = this.state;
    const broker = user.proposalBroker || {};
    const noGodmode = this.props.browser.lessThan.w840 || this.props.browser.is.w840;

    let askButtonText;
    if (browser.lessThan.w1060 && (browser.greaterThan.w600 || browser.is.w600) ) askButtonText = 'Ask a Question'
    else askButtonText = `Ask ${broker.firstName} a Question`;

    if (questionActive) askButtonText = 'Submit Question';

    const proceeding = !!user.meta.miniproposal.agreedToProceed;

    return (
      <Container active={active} id='next-steps'>
        <Form onSubmit={this.props.handleSubmit(this.save.bind(this))}>
        
          {editMode &&
            <SectionButton
              resetSection={() => this.resetSection()}
              enableEdit={() => this.enableEdit()}
              isEditable={active}
              cancelEdit={() => this.cancel()}
              type="overview-section" 
              style={{ float: 'right', position: 'absolute', top: '-10px', right: '0' }}
              cancelSaveContainer="20px"
            />
          }


          <Heading>Next Steps</Heading>
            <div className='broker-section'>
              <div className="broker">
              <img src={`${uploadUrl}/brokers/big${broker.id}.png`} alt={broker.name} />
              <div className='broker-detail'>
                <div className="name">{broker.name}</div>
                <div className="position">{broker.title}</div>
                <ul>
                  <li className="email">{broker.email}</li>
                  <li className="phone">{broker.mobile}</li>
                  <li className="hotline">1800 931 678</li>
                </ul>
                <button onClick={() => window.open(broker.calendlyUrl, "_blank")}>
                  <span>Book time in my calendar</span>
                </button>
              </div>
              
            </div>
            <div className="broker-info">
              <p>
                {proceeding ? 
                  <Paragraph dangerouslySetInnerHTML={{ __html: sanitizeHtml(globalSettings.overview_next_agreed)}}/> 
                  :
                  <EditableParagraph 
                    richText={!!active}
                    innerRef={this.inputRef} 
                    allowLineBreaks 
                    className={active ? 'active' : ''} 
                    name='body' 
                    disabled={!active}
                  />
                }
                
              </p>

                

                <QuestionContainer active={questionActive}>
                  <QuestionHeading>Ask {broker.firstName} a Question</QuestionHeading>
                  <TextArea id="question" width="large" position="left" type="text" placeholder="" active={questionActive} transitioning={questionTransitioning}/>
                </QuestionContainer>
               
                

                <HorizontalCenter singleButtonOnly={proceeding}>
                  <SpaceBetween singleButtonOnly={proceeding}>
                    <Button 
                      singleButtonOnly={proceeding}
                      color='#2291FF' 
                      onClick={() => !questionActive ? this.questionActive() : this.submitQuestion()}
                    >
                      <ButtonSpinner loading={questionSubmitting}/>
                      {askButtonText}
                    </Button>
                    {!proceeding &&
                      <Button color='#26BD27' onClick={() => this.props.openModal("nextStep")}>Agree To Proceed</Button>
                    }
                  </SpaceBetween>
                </HorizontalCenter>

                {questionSubmitted && (<MessageSent singleButtonOnly={proceeding} className="message-sent">Your question has been sent! 🙂</MessageSent>)}

            </div>



          </div>
          

       
        </Form>
      </Container>
    )
  }
}

const Paragraph = styled.div`
  line-height: 150%;
  min-height: 50px;
  outline: none;
  
  b {
    font-weight: 700;
  }

  i {
    font-style: italic;
  }

  u {
    text-decoration: underline;
  }

  ul {
    margin-top: 20px;

    li:before {
      content: '•';
      margin-left: -20px;
      margin-right: 10px;
    }

    li {
      list-style: none inside !important;
      margin-left: 30px;
      margin-bottom: 15px;
    }
  }

  ol {
    margin-top: 20px;

    li {
      list-style: decimal inside !important;
      margin-left: 10px;
      margin-bottom: 15px;
    }
  }

  &.active {
    border: solid 1px #A3A8B2;
    border-radius: 3px;
  }
`;

const QuestionHeading = styled.h2`
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 600;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    color: #223241;
`;

const TextArea = styled(TextAreaBase)`
    appearance: none;
    resize: vertical;
    border: 1px solid #D8DCE7;
    padding: 15px;
    font-weight: 400;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    width: 100%;
    height: 0px;
    border-radius: 4px;

    ${p => p.transitioning && css`
      transition: all 0.5s ease-in-out;
    `}
    
    ${p => p.active && css`
      height: 200px;
  `}
`;

const QuestionContainer = styled.div`
  height: 0px;
  overflow: hidden;
  
  ${p => p.active && css`
    height: auto;
    overflow: auto;
  `}
`;

const MessageSent = styled.div`
  font-weight: 700;
  text-align: center;
  margin-top: 20px;

  ${props => props.singleButtonOnly && css`
    text-align: start;
  `}
`;


const BIG_BUTTON_WIDTH = 205;
const SMALL_BUTTON_WIDTH = 160;

const HorizontalCenter = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;

  ${props => props.singleButtonOnly && css`
    justify-content: start;
  `}
`

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;

  width: ${BIG_BUTTON_WIDTH * 2 + 50}px;
  min-width: ${BIG_BUTTON_WIDTH}px;
  max-width: ${BIG_BUTTON_WIDTH * 2 + 50}px;

  @media (max-width: 950px) and (min-width: 680px) {
    width: ${SMALL_BUTTON_WIDTH * 2 + 50}px;
    min-width: ${SMALL_BUTTON_WIDTH}px;
    max-width: ${SMALL_BUTTON_WIDTH * 2 + 50}px;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    justify-content: center;
    width: ${BIG_BUTTON_WIDTH}px;
    min-width: ${BIG_BUTTON_WIDTH}px;
    max-width: ${BIG_BUTTON_WIDTH}px;
  }

  ${props => props.singleButtonOnly && css`
    @media (max-width: 480px) {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    }
  `}
`;

const Button = styled.button`
  width: ${BIG_BUTTON_WIDTH}px;
  min-width: ${BIG_BUTTON_WIDTH}px;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 700;
  border-radius: 4px;
  padding: 12px 0 12px 0;
  color: white;
  border: none; 
  background-color: ${p => p.color};
  cursor: pointer;

  @media (max-width: 950px) and (min-width: 680px) {
    width: ${SMALL_BUTTON_WIDTH}px;
    min-width: ${SMALL_BUTTON_WIDTH}px;
  }

  @media (max-width: 480px) {
    margin-bottom: 20px;
  }

  ${props => props.singleButtonOnly && css`
    @media (max-width: 480px) {
      width: 100% !important;
    }
  `}

  transition: 0.5s background-color;

  &:focus,
  &:hover {
    background-color: ${p => lighten(0.05, p.color)};
  }

  &:active {
    background-color: ${p => lighten(0.05, p.color)};
    
  }
`;

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openModal,
    requestPatchUser,
  }, dispatch);
}

const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state, props) => {
  const { nextSteps } = state.user.meta.miniproposal.overview || {}
  const { globalSettings } = state.user;
  const body = (nextSteps || {}).body || globalSettings[BODY_NAME];

  return {
    globalSettings,
    body: ((state.form[FORM_NAME] || {}).values || {}).body,
    user: state.user,
    miniProposal: state.user.meta.miniproposal,
    initialValues: { body },
    formValues: (state.form[FORM_NAME] || {}).values || {},
    browser: state.browser,
    question: selector(state, 'question'),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    destroyOnUnmount: false,
  }) (NextSteps));